<template>
  <b-card-code>
    <!-- search input -->
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Search</label>
          <b-form-input
              v-model="searchTerm"
              :placeholder="$t('Search')"
              type="text"
              class="d-inline-block"
          />
        </div>
      </b-form-group>
    </div>
      <div class="custom-search d-flex justify-content-start">
          <vue-excel-xlsx
              :data="getData"
              :columns="columns10"
              :filename="'filename'"
              :sheetname="'subjects'"
          >
              <b>Excel</b>
          </vue-excel-xlsx>
          <vue-json-to-csv :json-data="getData"
                           :csv-title="'My_CSV'"
          >
              <button>
                  <b>CSV</b>
              </button>
          </vue-json-to-csv>
          <button @click="pdfGenerate()">
              <b>PDF</b>
          </button>
      </div>
    <!-- table -->
    <vue-good-table
        style="white-space: nowrap;text-align: center;vertical-align : middle;"
        :columns="columns"
        :rows="students"
        :rtl="direction"
        :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
        :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
          slot="table-row"
          slot-scope="props"
      >
        <!-- Column: index -->
        <span v-if="props.column.field === 'id'">
          {{ props.row.originalIndex + 1 }}
        </span>
        <span v-else-if="props.column.field === 'image'">
          <template>
            <img :src="props.row.image?imgShow(props.row.image):'/student_face.gif'" width="50" height="50" alt="">
          </template>
        </span>
        <span v-else-if="props.column.field === 'classes_id'">
          {{ $store.getters.classByID(props.row.classes_id) }}
        </span>
        <span v-else-if="props.column.field === 'version_id'">
          {{ $store.getters.versionByID(props.row.version_id) }}
        </span>
        <span v-else-if="props.column.field === 'wing_id'">
          {{ $store.getters.wingByID(props.row.wing_id) }}
        </span>
        <span v-else-if="props.column.field === 'student_group_id'">
          {{ $store.getters.student_groupByID(props.row.student_group_id) }}
        </span>

        <span v-else-if="props.column.field === 'academic_year_id'">
          {{ $store.getters.academic_yearByID(props.row.academic_year_id) }}
        </span>
        <span v-else-if="props.column.field === 'is_active'">
          <b-badge :variant="statusVariant(props.row.is_active)">
            {{ props.row.is_active?'Active':'Deactivated' }}
          </b-badge>
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <template>
              <b-button variant="secondary" @click="viewDetails(props.row)">
                  <feather-icon class="text-body " icon="EyeIcon" size="15"/>
              </b-button>
              <b-button v-if="$can('promotion','Student')" v-ripple.400="'rgba(113, 102, 240, 0.15)'" @click="showModal(props.row)"
                        v-b-modal.modal-login variant="primary">
                  <feather-icon class="text-body " icon="EditIcon" size="15"/>
              </b-button>
              </template>
          </span>
        </span>
      </template>

      <!-- pagination -->
      <template
          slot="pagination-bottom"
          slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
            <b-form-select
                v-model="pageLength"
                :options="['10','15','20','50','100','500']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>

    <!-- modal login-->
    <b-modal ref="my-modal" hide-footer size="lg" :title="$t('Student Information Edit')">
      <!-- form -->
      <validation-observer ref="simpleRules">
        <form method="post" ref="update_student" @submit="validationForm" action="javascript:void(0)">
          <b-row>
            <b-col md="4">
              <b-card-text>
                <span>{{ $t('Student Name') }} </span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  :name="$t('student name')"
                  rules="required"
              >
                <b-form-input readonly v-model="selected_row.userable.name"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              :placeholder="$t('Write student name')"
                              class="form-control">
                </b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <b-card-text>
                <span>{{ $t('Student ID') }} </span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  :name="$t('student email')"
                  rules=""
              >
                <b-form-input readonly v-model="selected_row.cadet_no"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              :placeholder="$t('Write student ID')"
                              class="form-control">
                </b-form-input>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <b-card-text>
                <span>{{ $t('Select Class') }} </span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  :name="$t('class')"
                  rules="required"
              >
                <select name="classes_id" v-model="classes_id"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" class="form-control">
                  <option :value="null">{{ $t('Select One') }}</option>
                  <option v-for="(cls, key) in classes" :value="cls.id" :key="key">
                    {{ cls.name }}
                  </option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <b-card-text>
                <span>{{ $t('Select Version') }} </span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  :name="$t('version')"
                  rules="required"
              >
                <select name="version_id" v-model="version_id"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" class="form-control">
                  <option :value="null">{{ $t('Select One') }}</option>
                  <option v-for="(version, key) in versions" :value="version.id" :key="key">
                    {{ version.name }}
                  </option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <b-card-text>
                <span>{{ $t('Select Shift') }} </span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  :name="$t('shift')"
                  rules="required"
              >
                <select name="shift_id" v-model="shift_id"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" class="form-control">
                  <option :value="null">{{ $t('Select One') }}</option>
                  <option v-for="(shift, key) in shifts" :value="shift.id" :key="key">
                    {{ shift.name }}
                  </option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <b-card-text>
                <span>{{ $t('Select Student Group') }} </span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  :name="$t('section')"
                  rules="required"
              >
                <select name="student_group_id" v-model="student_group_id"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" class="form-control">
                  <option :value="null">{{ $t('Select One') }}</option>
                  <option v-for="(group, key) in student_groups" :value="group.id" :key="key">
                    {{ group.name }}
                  </option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <b-card-text>
                <span>{{ $t('Select Section') }} </span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  :name="$t('section')"
                  rules="required"
              >
                <select name="section_id" v-model="section_id"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" class="form-control">
                  <option :value="null">{{ $t('Select One') }}</option>
                  <option v-for="(section, key) in sections" :value="section.id" :key="key">
                    {{ section.name }}
                  </option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <b-card-text>
                <span>{{ $t('Select Academic Year') }} </span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  :name="$t('academic year')"
                  rules="required"
              >
                <select name="academic_year_id" v-model="academic_year_id"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" class="form-control">
                  <option :value="null">{{ $t('Select One') }}</option>
                  <option v-for="(aYear, key) in academic_years" :value="aYear.id" :key="key">
                    {{ aYear.year }}
                  </option>
                </select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
          </b-row>
          <!-- submit button -->
          <b-col>
            <b-button class="mt-2" variant="primary" type="submit">{{ $t('Promotion') }}</b-button>
          </b-col>
        </form>
      </validation-observer>
    </b-modal>
    <!-- view model -->
    <b-modal ref="information-view" hide-footer size="xl" :title="$t('Information View')">
      <b-row>
        <b-col lg="4">
          <b-card-group deck>
            <b-card>
              <div class="text-center">
                <b-avatar :src="student.image?imgShow(student.image):'/student_face.gif'" size="6rem"></b-avatar>
                <h1 class="mb-0">{{ (student.userable) ? student.userable.name : '' }}</h1>
                <h6 class="my-2">Student</h6>
              </div>
              <table>
                <tr>
                  <td class="font-weight-bold">Student No.</td>
                  <td>: {{ student.cadet_no }}</td>
                </tr>
                <tr>
                  <td class="font-weight-bold">Role</td>
                  <td>: {{ student.userable ? roleByID(student.userable.role_id) : '' }}</td>
                </tr>
                <tr>
                  <td>Wing</td>
                  <td>: {{ wingByID(student.wing_id) }}</td>
                </tr>
              </table>
            </b-card>
          </b-card-group>
        </b-col>
        <b-col lg="8">
          <b-card>
            <b-tabs pills card>
              <b-tab title="Profile" active>
                <b-card-body>
                  <b-row>
                    <b-col md="5">
                      <table>
                        <tr v-if="student.roll">
                          <td>Roll</td>
                          <td>: {{ student.roll }}</td>
                        </tr>
                        <tr>
                          <td>Email</td>
                          <td>: {{ (student.userable) ? student.userable.email : '' }}</td>
                        </tr>
                        <tr>
                          <td>Phone number</td>
                          <td>: {{ (student.userable) ? student.userable.phone_number : '' }}</td>
                        </tr>
                        <tr>
                          <td>Gender</td>
                          <td>: {{ student.userable ? genderById(student.userable.gender_id) : '' }}</td>
                        </tr>
                        <tr>
                          <td>Blood group</td>
                          <td>:{{ bloodGroupById((student.userable) ? student.userable.blood_group_id : '') }}</td>
                        </tr>
                        <tr>
                          <td>Religion</td>
                          <td>: {{ religionById((student.userable) ? student.userable.religion_id : '') }}</td>
                        </tr>
                        <tr>
                          <td>Version</td>
                          <td>: {{ versionByID(student.version_id) }}</td>
                        </tr>
                        <tr>
                          <td>Shift</td>
                          <td>: {{ shiftByID(student.shift_id) }}</td>
                        </tr>
                      </table>

                    </b-col>
                    <b-col md="2"></b-col>
                    <b-col md="5">
                      <table>
                        <tr>
                          <td>Class</td>
                          <td>: {{ classByID(student.classes_id) }}</td>
                        </tr>
                        <tr>
                          <td>Student Group</td>
                          <td>: {{ student_groupByID(student.student_group_id) }}</td>
                        </tr>
                        <tr>
                          <td>Section</td>
                          <td>: {{ sectionById(student.section_id) }}</td>
                        </tr>
                        <tr>
                          <td>Academic year</td>
                          <td>: {{ academic_yearByID(student.academic_year_id) }}</td>
                        </tr>
                        <tr>
                          <td>Special promotion</td>
                          <td :style="student.is_special_promotion?'color:green':'color:red'">:
                            {{ (student.is_special_promotion === 1) ? 'yes' : 'no' }}
                          </td>
                        </tr>
                        <tr>
                          <td>Date of birth</td>
                          <td>: {{ student.date_of_birth }}</td>
                        </tr>
                        <tr>
                          <td>Status</td>
                          <td :style="student.is_active?'color:green':'color:red'">:
                            {{ (student.is_active === 1) ? 'active' : 'inactive' }}
                          </td>
                        </tr>
                      </table>
                    </b-col>
                  </b-row>
                </b-card-body>
              </b-tab>
              <b-tab title="Parent">
                <b-card-body>
                  <b-row>
                    <b-col md="5">
                      <table>
                        <tr>
                          <td>Father Name</td>
                          <td>: {{ (student.parent) ? student.parent.father_name : '' }}</td>
                        </tr>
                        <tr>
                          <td>Father Profession</td>
                          <td>: {{ (student.parent) ? student.parent.father_profession : '' }}</td>
                        </tr>
                        <tr>
                          <td style="width:120px">Father Phone Number</td>
                          <td>: {{ (student.parent) ? student.parent.father_phone_number : '' }}</td>
                        </tr>
                        <tr>
                          <td>Father NID</td>
                          <td>: {{ (student.parent) ? student.parent.father_nid_no : '' }}</td>
                        </tr>
                        <tr v-if="student.parent ? student.parent.father_email: '' ">
                          <td>Father Email</td>
                          <td>: {{ (student.parent) ? student.parent.father_email : '' }}</td>
                        </tr>
                        <tr>
                          <td>Mother Name</td>
                          <td>: {{ (student.parent) ? student.parent.mother_name : '' }}</td>
                        </tr>
                        <tr>
                          <td>Mother Profession</td>
                          <td>: {{ (student.parent) ? student.parent.mother_profession : '' }}</td>
                        </tr>
                        <tr>
                          <td style="width:120px">Mother Phone Number</td>
                          <td>: {{ (student.parent) ? student.parent.mother_phone_number : '' }}</td>
                        </tr>
                        <tr>
                          <td>Mother NID</td>
                          <td>: {{ (student.parent) ? student.parent.mother_nid_no : '' }}</td>
                        </tr>
                      </table>
                    </b-col>
                    <b-col md="2"></b-col>
                    <b-col md="5">
                      <table>
                        <tr v-if="student.parent ? student.parent.mother_email: '' ">
                          <td>Mother Email</td>
                          <td>: {{ (student.parent) ? student.parent.mother_email : '' }}</td>
                        </tr>
                        <tr>
                          <td>Present Address</td>
                          <td style="min-width:120px">: {{
                              (student.parent) ? student.parent.present_address : ''
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td>Permanent Address</td>
                          <td>: {{ (student.parent) ? student.parent.permanent_address : '' }}</td>
                        </tr>
                        <tr>
                          <td>Local Guardian Name</td>
                          <td>: {{ (student.parent) ? student.parent.local_guardian_name : '' }}</td>
                        </tr>
                        <tr>
                          <td>Local Guardian Phone</td>
                          <td>: {{ (student.parent) ? student.parent.local_guardian_phone_number : '' }}</td>
                        </tr>
                        <tr>
                          <td>Relation</td>
                          <td>: {{ (student.parent) ? student.parent.relation : '' }}</td>
                        </tr>
                        <tr>
                          <td>Local Guardian Address</td>
                          <td>: {{ (student.parent) ? student.parent.local_guardian_address : '' }}</td>
                        </tr>
                        <tr>
                          <td>Status</td>
                          <td :style="student.parent?student.parent.is_active?'color:green':'color:red':'color:red'">:
                            {{ student.parent ? student.parent.is_active ? 'active' : 'Inactive' : 'Inactive' }}
                          </td>
                        </tr>
                      </table>
                    </b-col>
                  </b-row>
                </b-card-body>
              </b-tab>
            </b-tabs>
          </b-card>
        </b-col>
      </b-row>
      <!--            {{ students }}-->
    </b-modal>

  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BButton, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BCardText,
  BRow, BCol, BAvatar, BCardGroup, BCard, BCardBody, BTabs, BTab,
} from 'bootstrap-vue'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {
  required, integer, email,
} from '@validations'
import {VueGoodTable} from 'vue-good-table'
import store from '@/store/index'
import apiCall from "@/libs/axios";
import Ripple from 'vue-ripple-directive'
import {mapGetters} from "vuex";
import VueExcelXlsx from "vue-excel-xlsx";
import VueJsonToCsv from 'vue-json-to-csv'
import Vue from "vue";
import download from "downloadjs";

Vue.use(VueExcelXlsx);

export default {
  name: 'StudentPromotion',
  components: {
    BCardCode, ValidationProvider, ValidationObserver,
    VueGoodTable, BCardText, BRow, BCol,
    BBadge, BAvatar,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BButton,
    BCardGroup, BCard, BCardBody, BTabs, BTab, VueJsonToCsv
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required, integer, email,
      pageLength: 10,
      dir: false,
      model_mode: 'add',
      student_photo: null,
      all_sections: [],
      filter_sections: [],
      name: '',
      selected_row: {},
      student: {},
      columns: [
        {
          label: this.$t('SL'),
          field: 'id',
        },
        {
          label: this.$t('Photo'),
          field: 'image',
        },
        {
          label: this.$t('Student Number'),
          field: 'cadet_no',
        },
        {
          label: this.$t('Name'),
          field: 'userable.name',
        },
        {
          label: this.$t('Class'),
          field: 'classes_id',
        },
        {
          label: this.$t('Student Group'),
          field: 'student_group_id',
        },
        {
          label: this.$t('Academic Year'),
          field: 'academic_year_id',
        },
        {
          label: this.$t('Status'),
          field: 'is_active',
        },
        {
          label: this.$t('Action'),
          field: 'action',
        },
      ],
      searchTerm: '',
      status: {
        1: this.$t('active'),
        0: this.$t('deactivate'),
      },
      classes_id:null,
      version_id:null,
      shift_id:null,
      student_group_id:null,
      section_id:null,
      academic_year_id:null,
        columns10: [
            {
                label: 'Student Number',
                field: 'CadetNumber',
            },
            {
                label: 'Name',
                field: 'Name',
            },
            {
                label: 'Class',
                field: 'Class',
            },
            {
                label: 'Version',
                field: 'Version',
            },{
                label: 'Wing',
                field: 'Wing',
            },
            {
                label: 'Student Group',
                field: 'StudentGroup',
            },
            {
                label: 'Academic Year',
                field: 'AcademicYear',
            },
            {
                label: 'Status',
                field: 'Status',
            },
        ],
    }
  },
  methods: {
    viewDetails(row) {
      this.student = row;
      this.$refs['information-view'].show()
    },
    imgShow(path) {
      return process.env.VUE_APP_ENV_RESOURCHURL + path;
    },
    statusChange(id) {
      apiCall.put(`/student/status/change${id}`).then((response) => {
        this.$toaster.success(response.data.message);
        this.$store.commit('STUDENT_STATUS_CHANGE', id);
      }).catch((error) => {
        this.$toaster.error(error.response.data.message);
        this.$store.dispatch('GET_ALL_STUDENT');
      });
    },
    showModal(row) {
      this.selected_row = row;
      this.$refs['my-modal'].show()
    },
    hideModal() {
      this.$refs['my-modal'].hide()
    },
    filterSections() {
      //this.selected_row.section_id=null;
      if (!this.selected_row.wing_id || !this.selected_row.academic_year_id) this.filter_sections = [];
      else {
        this.filter_sections = this.map_sections.find((item) => item.id == this.selected_row.wing_id)?.sections;
      }
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          let data = new FormData(this.$refs.update_student);
          apiCall.post(`/single/student/promotion${this.selected_row.id}`, data).then((response) => {
            if (response.data.status === 'success') {
              this.$toaster.success(response.data.message);
              this.hideModal();
              this.$store.dispatch('GET_ALL_STUDENT');
              this.$refs.simpleRules.reset();
            } else {
              this.$toaster.error(response.data.message);
            }
          }).catch((error) => {
            if (error.response.status == 422) {
              Object.keys(error.response.data.errors).map((field) => {
                this.$toaster.error(error.response.data.errors[field][0]);
              })
            } else this.$toaster.error(error.response.data.message);
          });
        }
      })
    },
    findSections() {
      let data = new FormData();
      data.append('classes_id', this.selected_row.classes_id);
      data.append('academic_year_id', this.selected_row.academic_year_id);
      this.$store.dispatch('GET_ALL_MAP_SECTION', data);
    },
      pdfGenerate(){
          /*only change able*/
          let title="Students";
          let clm=['CadetNumber','Name','Class', 'Version', 'Wing','StudentGroup', 'AcademicYear', 'Status'];
          /*change able end*/
          let data =new FormData();
          data.append('title',title);
          data.append('columns',JSON.stringify(clm));
          data.append('data',JSON.stringify(this.getData));
          apiCall.post('/get/common/table/export/pdf',data,{responseType: 'blob'}).then((response)=>{
              const content = response.headers['content-type'];
              download(response.data,'subject.pdf', content)
          }).catch((error) => {
              this.$toaster.error(error.response.data.message);
          });
      },
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        1: 'light-success',
        0: 'light-danger',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    ...mapGetters(['students',
      'classes', 'classByID', 'versions', 'versionByID', 'shifts', 'shiftByID',
      'student_groups', 'student_groupByID', 'roles', 'roleByID',
      'academic_years', 'academic_yearByID', 'active_religions', 'religions', 'religionById',
      'genders', 'genderById', 'blood_groups', 'bloodGroupById', 'wings', 'wingByID', 'sections', 'sectionById']),
      getData(){
          return this.students.map(item => ({
              CadetNumber: item.cadet_no?item.cadet_no :'',
              Name: item.userable?item.userable.name:'',
              Class: item.classes_id?this.classByID(item.classes_id):'',
              Version: item.version_id?this.versionByID(item.version_id):'',
              Wing: item.wing_id?this.wingByID(item.wing_id):'',
              StudentGroup: item.student_group_id?this.student_groupByID(item.student_group_id):'',
              AcademicYear: item.academic_year_id?this.academic_yearByID(item.academic_year_id):'',
              Status: item.is_active ? 'Active' : 'Inactive',
          }));
      },
  },
  created() {
    if (this.students.length < 1) this.$store.dispatch('GET_ALL_STUDENT');
    if (this.classes.length < 1) this.$store.dispatch('GET_ALL_CLASSES');
    if (this.student_groups.length < 1) this.$store.dispatch('GET_ALL_STUDENT_GROUP');
    if (this.academic_years.length < 1) this.$store.dispatch('GET_ALL_ACADEMIC_YEAR');
    if (this.wings.length < 1) this.$store.dispatch('GET_ALL_WING');
    if (this.religions.length < 1) this.$store.dispatch('GET_ALL_RELIGION');
    if (this.blood_groups.length < 1) this.$store.dispatch('GET_ALL_BLOOD_GROUP');
    if (this.genders.length < 1) this.$store.dispatch('GET_ALL_GENDER');
    if (this.versions.length < 1) this.$store.dispatch('GET_ALL_VERSION');
    if (this.shifts.length < 1) this.$store.dispatch('GET_ALL_SHIFT');
    if (this.roles.length < 1) this.$store.dispatch('GET_ALL_ROLE');
    if (this.genders.length < 1) this.$store.dispatch('GET_ALL_GENDER');
    if (this.sections.length < 1) this.$store.dispatch('GET_ALL_SECTION');
  },
  watch: {
    selected_row() {
      if (this.selected_row) {
        this.findSections();
        this.filterSections();
      }
    }
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
